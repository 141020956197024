@import "../../styles/colors.scss";
@import "../../styles/constants.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.sideMenuNav {
  div:first-child {
    margin-top: 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 30px;

    img {
      height: 19px;
    }
  }
}

.itemsMenu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 250;
  width: 511px;

  height: 100vh;
  overflow: auto;
  background-color: $light-grey-color;

  animation: slideInFromLeft .5s ease-out;

  @media screen and (max-width: $mobile-screen) {
    width: 100%;
  }
  
  .goBackHolder {
    width: 107px;
    margin-top: 45px;

    img {
      cursor: pointer;
      height: 19px;
      transform: rotate(180deg);
    }
  }

  .itemsHolder {
    min-height: 100vh;
    
    padding: 21px 10% 150px;

    @media screen and (max-width: 567px) {
      padding: 0px 10% 150px;
    }

    @media screen and (max-width: $mobile-screen) {
      padding: 0px 5% 150px;
    }

    .leadingNavButton {
      font-size: 16px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      cursor: pointer;
      user-select: none;
    }
  }
}

.itemsMenuHide {
  animation: slideOutToLeft .5s ease-out;
}