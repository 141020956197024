@import '../../styles/colors.scss';
@import '../../styles/constants.scss';

@keyframes show-header {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }  
}
@keyframes hide-header {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.hideHeader {
  animation: hide-header 0.5s ease-in;
}
.showHeader {
  display: flex !important;
  animation: show-header 0.5s ease-out;
}

.nevoLogo, .hamburgerHolder, .subNavButtonHolder > p {
  cursor: pointer;
  user-select: none;
}

.nevoLogo {
  position: relative;
  bottom: 9px;

  @media screen and (max-width: $small-screen) {
    bottom: 7px;
  }
}

.navButton {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  span {
    line-height: 20px;
  }  

  .coolUnderline {
    position: relative;
    top: 8px;

    min-width: 22px;
    width: 1.65vw;
    max-width: 30px;
    
    max-height: 3px;
    height: 0.165vw;
    min-height: 2px;

    background-color: transparent !important;

    user-select: none;

    @media screen and (max-width: $small-screen) {
      top: 6.5px; 
    }
  }
  .displayUnderline {
    background-color: $blue-color !important; 
  }

  .subNavButtonHolder {
    max-width: 25px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    padding-top: 40px;
    padding-bottom: 40px;

    p:first-child {
      margin-top: 0;
    }
    p {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;

      white-space: nowrap;
    }

    @media screen and (max-width: $small-screen) {
      padding-top: 34px;
      padding-bottom: 34px;

      p {
        margin-top: 16px;
        font-size: 14px;
      } 
    }
  }
}

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  justify-content: space-between;

  padding-top: 27px;
  padding-left: 14vw;
  padding-right: 14vw;

  overflow-y: hidden;
  width: 100vw;
  height: 75px;

  transition: background-color .300s, height .300s;

  background: $light-grey-color;
  
  @media screen and (max-width: $small-screen) {
    height: 60px;
    padding-top: 21px;
    padding-left: 10%;
    padding-right: 10%;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }

  .desktopNav {
    display: flex;
    justify-content: space-between;
    
    // width: 440px; for 3 items
    width: 550px;
    
    @media screen and (max-width: $small-screen) {
      // width: 365px; for 3 items
      width: 450px;
    }

    .navButton {
      color: $black-color;
    }
  }

  .hamburgerHolder {
    display: flex;
    justify-content: flex-end;
    width: 120px;

    @media screen and (max-width: $small-screen) {
      width: 107px;
    }

    .hamburgerIcon {
      min-width: 16px;
      width: 1vw;
      max-width: 18px;
      
      min-height: 16px;
      height: 1vw;
      max-height: 18px;
    }
  }
}

.headerContainerOpenned, .fixedHeader {
  max-height: unset !important;
  background: $light-grey-color !important;

  .navButton {
    .nevoLogo, span, span > a, .subNavButtonHolder > p {
      color: $black-color !important;
    }
  }
}
.fixedHeader {
  box-shadow: 0 -6px 10px 5px rgba($color: $black-color, $alpha: 0.5);
}


.transparentHeader {
  background: transparent;

  .desktopNav {
    .navButton {
      span, .subNavButtonHolder > p {
        color: $white-color;
      } 
    }
  }
}

.blackHeader {
  background-color: rgba($color: $black-color, $alpha: 0.5);
}

.blackStyle {
  .desktopNav {
    .navButton {
      span, .subNavButtonHolder > p {
        color: $black-color;
      } 
    }
  }
}

.navCharging {
  .navChargingSpan {
    .navChargingA {
      color: $blue-color !important;
    }
  }
}