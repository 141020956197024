@import "../../styles/colors.scss";
@import "../../styles/constants.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}


.sideMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 567px) {
    padding-top: 21px;
    height: 60px;

    align-items: flex-start;

    .nevoLogo {
      position: relative;
      bottom: 7px;
    }
  }
}

.sideMenu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  width: 511px;

  height: 100vh;
  overflow: auto;
  background-color: $light-grey-color;

  animation: slideInFromLeft .5s ease-out;

  @media screen and (max-width: $mobile-screen) {
    width: 100%;
  }

  .sideMenuContent {
    min-height: 100vh;
    padding: 30px 40px 40px;

    @media screen and (max-width: $small-screen) {
      padding: 21px 10% 40px;
    }

    @media screen and (max-width: 567px) {
      padding: 0 10% 40px;
    }

    @media screen and (max-width: $mobile-screen) {
      padding: 0 5% 20px;
    }

    .closeIconContainer {
      width: 107px;

      img {
        cursor: pointer;

        width: 16px;
        height: 16px;
      }

      @media screen and (max-width: 567px) {
        display: flex;
        justify-content: flex-end;

        img {
          margin-top: 2.5px;
        }
      }
    }

    .menuContent {
      margin-top: 53px;
      
      @media screen and (max-width: $small-screen) {
        margin-top: 45px;
      }

      p:first-child {
        margin-top: 0;
      }
      p {
        margin-top: 30px;
      }
      
      .nevoChargingButton {
        color: $blue-color;
      }
    }

    .bottomContent {
      position: fixed;
      bottom: 40px;
      z-index: 201;

      @media screen and (max-width: $mobile-screen) {
        width: 90vw;
      }

      @media screen and (max-height: 530px) {
        margin-top: 50px;
        position: static;
      }
    }
  }
}

.sideMenuHide {
  animation: slideOutToLeft .5s ease-in;
}

.fadingCover {
  z-index: 150;
  cursor: pointer;
}